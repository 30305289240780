<template>
    <article class="blog-basic-card">
        <NuxtLink no-prefetch :to="fullPath" rel="follow" class="image">
            <img :src="post.image?.url" :alt="post.title" :title="post.title" width="12" height="12" />
        </NuxtLink>

        <div class="info">
            <span class="info__tag">{{ blogTag }}</span>
            <NuxtLink no-prefetch :to="fullPath" rel="follow" class="info__title">{{ postTitle }}</NuxtLink>
            <div class="footer">
                <div class="footer__author" v-if="post.author">
                    <img
                        :src="post.author.image.url"
                        :alt="post.author.name"
                        width="12"
                        height="12"
                        class="footer__author-image"
                    />
                    {{ post.author.name }}
                </div>
                <p class="reading-time">
                    <img :src="$assets.gray.clock" width="12" height="12" alt="Icono visualizaciones" />
                    {{ post.reading_time }} {{ $lang.components.cardBlog.minutes }}
                </p>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import type { Api } from '~/global'
import type { Models } from '~/types/models'
import { replacePlaceholders } from '~/util/textFunction'

export default defineComponent({
    name: 'CardBlog',
    props: {
        post: {
            type: Object as PropType<Models.Article>,
            required: true,
        },
        categorySlug: {
            type: String,
        },
        blogTag: {
            type: String,
        },
    },
    computed: {
        content(): string {
            return this.post.content?.replace(/<[^>]*>?/gm, ' ') || ''
        },
        truncatedContent(): string {
            return this.content.slice(0, 120)
        },
        fullPath(): string {
            return '/blog/' + (this.post.category?.slug || this.categorySlug) + '/' + this.post.slug
        },
        postTitle(): string {
            const { currentDate } = useDateFunctions()
            const currenDates = currentDate()
            const [postTitle] = replacePlaceholders(
                [
                    [':month', currenDates.month || ''],
                    [':year', String(currenDates.year) || ''],
                ],
                [this.post.title || ''],
            )
            return postTitle
        },
    },
})
</script>

<style lang="postcss" scoped>
.blog-basic-card {
    @apply flex min-h-full flex-col items-center rounded bg-white text-sm lg:hover:outline lg:hover:outline-1 lg:hover:outline-gray-300;
    .image {
        @apply block h-52 w-full;
        img {
            @apply h-full w-full rounded-t object-cover;
        }
    }
    .info {
        @apply p-[25px];

        &__tag {
            @apply rounded-[3px] bg-[#feb40133]/[0.2] px-2 py-0.5 text-sm font-semibold uppercase text-[#feb401];
        }
        &__title {
            @apply mb-10 mt-2.5 line-clamp-2 text-xl font-semibold;
        }

        .footer {
            @apply flex items-end justify-between text-sm text-gray-800;

            &__author {
                @apply flex items-center gap-x-2.5 text-sm capitalize text-site-gray;

                &-image {
                    @apply block size-5 rounded-full bg-gray-200 object-contain;
                }
            }
            .reading-time {
                @apply flex items-center gap-1 text-sm text-site-gray;
                img {
                    @apply h-3;
                }
            }
        }
    }
}
</style>
